<template>
  <div class="item-container" :class="getClassStatusItem(service.status)">
    <div class="item-container_header">
      <div class="item-container_status">
        <div
          class="item-container_status-icon"
          :class="getClassStatusIcon(service.status)"
        ></div>
        <div class="item-container_status_text">
          {{ 'status_' + service.status | localize }}
        </div>
      </div>
      <div class="item-container_name-services">
        {{ service.name }}
      </div>
    </div>
    <div class="item-container_main-service" v-if="service.amount && service.payment && service.return">
      <div class="item-container_additional-information">
        <div class="additional-information_title">
          {{ 'bank_sum' | localize }}
        </div>
        <div class="additional-information_sum">
          {{ service.amount | filterSum }} <span class="icon-₪"></span>
        </div>
      </div>
      <div class="item-container_additional-information">
        <div class="additional-information_title">
          {{ 'monthly_payment' | localize }}
        </div>
        <div class="additional-information_sum">
          {{ service.payment | filterSum }} <span class="icon-₪"></span>
        </div>
      </div>
      <div class="item-container_additional-information">
        <div class="additional-information_title">
          {{ 'bank_return' | localize }}
        </div>
        <div class="additional-information_sum">
          {{ service.return | filterSum }} <span class="icon-₪"></span>
        </div>
      </div>
    </div>
    <div v-else><br><br></div>
    <div class="item-container_button-container">
      <app-button
        v-if="(service.status == 'files') && !docs"
        :onClick="goToPage"
        class="item-container_button"
        label-btn="services_documents_download"
      />
      <app-button
        v-if="(service.status == 'in_progress') && !docs"
        :onClick="goToPage"
        class="item-container_button"
        label-btn="services_continue"
      />
      <app-button
        v-if="(service.status == 'payment')"
        :onClick="goToPage"
        class="item-container_button"
        label-btn="pay"
      />
      <div
        v-if="service.status == 'active' || service.status == 'completed' || service.status == 'files' || service.status == 'checked'"
        class="item-container_payment-schedule"
        @click="downloadPaymentSchedule"
      >
        <div class="item-container_payment-schedule_arrow icon-vector"></div>
        <span class="item-container_payment-schedule_text">{{
          "download_payment_schedule" | localize
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { statusService } from '@/fixtures/profile'

import AppButton from '@/components/profile/AppButton'
export default {
  name: 'ItemService',
  components: { AppButton },
  props: {
    service: { type: Object, default: null },
    docs: { type: Boolean, default: false }
  },
  data () {
    return {}
  },
  filters: {
    filterSum: (value) => {
      if (!value) return ''
      return value.toLocaleString()
    }
  },
  computed: {
    getClassStatusIcon () {
      return (name) => {
        if (name) {
          return `item-container_status-icon__${name}`
        } else {
          return 'item-container_status-icon__waiting'
        }
      }
    },
    getStatus () {
      return statusService.find((item) => {
        if (this.service.status) {
          return item.value === this.service?.status
        } else {
          return 'noDocs'
        }
      })
    },
    getClassStatusItem () {
      return (name) => {
        if (name) {
          return `item-container__${name}`
        } else {
          return 'item-container__waiting'
        }
      }
    }
  },
  methods: {
    gotoServiceContinueURL (serviceId) {
      switch (serviceId) {
        case 1: this.$router.push('/mortgage/realty'); break
        case 2: this.$router.push('/refinance-mortgage/banks'); break
        case 3: this.$router.push('/credit/income'); break
        case 4: this.$router.push('/refinance-credit/income'); break
        default: this.$router.push('/'); break
      }
    },
    goToPage () {
      if (this.service.status === 'files') {
        this.$router.push({ name: 'documents' })
      } else if (this.service.status === 'payment') {
        this.$router.push({ name: 'Contract', params: { service: this.service.service_id, backlink: '/profile/services' } })
      } else if (this.service.status === 'in_progress') {
        this.gotoServiceContinueURL(this.service.service_id)
      }
    },
    downloadPaymentSchedule () {
      const requestParams = {
        responseType: 'blob',
        params: {
          user_id: this.$store.state.user.id || 0,
          user_service_id: this.service.id
        }
      }
      this.$store.commit('loading', true)
      this.axios
        .get('user_service/export', requestParams)
        .then((response) => {
          const fileName = this.service.name + '.xlsx'
          const fileLink = document.createElement('a')
          fileLink.href = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.ms-excel;charset=utf-8' }))
          fileLink.setAttribute('download', fileName)
          document.body.appendChild(fileLink)
          fileLink.click()

          this.$store.commit('loading', false)
        })
        .catch((e) => {
          this.error = 'server_error'
          this.$store.commit('loading', false)
          this.$scrollTo('.error__container', 300, { offset: -400 })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/default/vars.scss";

.item-container {
  box-sizing: border-box;
  border-radius: 5px;
  margin-bottom: 50px;
  border: 1px solid $white;

  .item-container_status_text {
    font-size: 12px;
  }
  &_status-icon {
    border-radius: 50%;
    width: 10px;
    height: 10px;
    margin-right: 10px;
    background: $green;
  }
  &_additional-information {
    display: flex;
  }
  .item-container_status {
    margin-bottom: 25px;
    display: flex;
    align-items: center;
  }
  .additional-information_sum {
    font-size: 16px;
    line-height: 20px;
  }
  .item-container_payment-schedule {
    display: flex;
    font-size: 16px;
    line-height: 26px;
    padding-top: 20px;
    cursor: pointer;
  }
  .item-container_payment-schedule_arrow {
    width: 30px;
    height: 27px;
    font-size: 27px;
    border-bottom: 1px solid $white;
    margin-right: 20px;
    text-align: center;

    &::before {
      margin: 0;
    }
  }
  &_button-container {
    .button {
      margin-top: 20px;
    }
  }
}

#app.locale_heb {
  .item-container_status-icon {
    margin-left: 10px;
    margin-right: 0;
  }
  .item-container .item-container_payment-schedule_arrow {
    margin-right: 0;
    margin-left: 20px;
  }
  .item-container_main-service {
    padding: 30px 0;
  }
}

#app.light-theme {
  .item-container {
    border: 1px solid $black;

    .item-container_payment-schedule_arrow {
      border-bottom: 1px solid $black;
    }
  }
  .item-container_status-icon {
    background: $green_dark;
  }
}

@media screen and (max-width: 1024px) {
  .item-container {
    width: 100%;
    max-width: none;
    padding: 20px;
    margin-bottom: 30px;

    &_main-service {
      border-bottom: 1px solid $green;
      padding-top: 30px;
      padding-bottom: 10px;
    }
    &_additional-information {
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }
    .item-container_additional-information {
      margin-bottom: 20px;
      font-size: 14px;
      line-height: 17px;
    }
    .item-container_name-services {
      font-weight: 500;
      font-size: 18px;
      margin-bottom: 5px;
    }
    &_button-container {
      padding-top: 15px;
      padding-bottom: 10px;
    }
  }
  .item-container_payment-schedule {
    justify-content: center;
  }
  .item-container_button {
    height: 40px;
    line-height: 40px;
    font-size: 14px;
  }
}

@media screen and (min-width: 1025px) {
  .item-container {
    padding: 30px;
    max-width: 710px;

    .item-container_button {
      width: 295px;
    }
    .item-container_name-services {
      font-size: 22px;
      line-height: 28px;
    }
    &_main-service {
      display: flex;
      padding: 30px 100px 30px 0;
      margin-bottom: 12px;
      justify-content: space-between;
      border-bottom: 1px solid $green;
    }
    .item-container_additional-information {
      display: flex;
      flex-direction: column;
      .additional-information_title {
        margin-bottom: 5px;
        font-size: 14px;
        line-height: 21px;
      }
      .additional-information_sum {
        font-size: 20px;
        line-height: 30px;
      }
    }
  }
  .item-container_button {
    height: 50px;
    font-size: 16px;
    line-height: 22px;
  }
}

#app.light-theme {
  .item-container_main-service {
    border-bottom: 1px solid $green_dark;
  }
}
</style>
